import Link, { LinkProps } from "next/link";
import React from "react";

type NavLinkProps = {
    children?: string | undefined
} & LinkProps

export default React.memo(NavLink);

function NavLink({ children, ...rest }: NavLinkProps) {

    return <Link {...rest}>
        <span className="cursor-pointer text-sm font-semibold primary-text hover:underline">
            {children}
        </span>
    </Link>
}