import clsx from "clsx"
import { useRouter } from 'next/router';
import NavLink from '../../../../components/ui/NavLink';
import Divider from '../../../../components/ui/Divider';
import FormError from '../../../../components/ui/FormError';
import { useApiFetch } from '../../../../hooks/useApiFetch';
import FormInput from '../../../../components/ui/FormInput';
import FormButton from '../../../../components/ui/FormButton';
import { FormEvent, useCallback, useMemo, useState } from 'react';
import { useAppContext, useAuthContext } from '../../../../frontend/AppContext';


export default function SignInPageBody() {
    const authCtx = useAuthContext();

    return <>

        {/* {
            authCtx.auth?.methods?.isSSOEnabled || true && <SSO />
        } */}

        {/* {
            authCtx.auth?.methods?.isSSOEnabled && authCtx.auth?.methods?.isSimpleAuthEnabled && <Divider />
        } */}

        {
            authCtx.auth?.methods?.isSimpleAuthEnabled && <SimpleAccess />
        }

    </>
}

function SSO() {

    const appCtx = useAppContext();

    const router = useRouter()

    // Read returnTo and pass to SSO
    const onSSO = useCallback(
        (e) => {
            e.preventDefault();
            router.push("/auth/sso-redirect")
        },
        [],
    )

    if (!appCtx?.tenant?.domains?.buildInPrefix) {
        return <></>
    }

    return <div id="btn-sso" className={clsx("mx-auto flex h-12 w-full cursor-pointer",
        "select-none flex-row items-center justify-center space-x-2 rounded-sm border",
        "border-gray-300 p-2 px-3 py-3 shadow-sm hover:bg-gray-100 hover:shadow")}>
        <div onClick={onSSO} className="text-sm font-semibold text-gray-800">
            {appCtx?.tenant?.texts.authSSO ?? "Single sign-on (SSO)"}
        </div>
    </div>
}

function SimpleAccess() {

    const router = useRouter()

    const appCtx = useAppContext();

    const [email, setEmail] = useState(router?.query?.email ? router?.query?.email as string : "")

    const [fromProtectedResource] = useState(router?.query?.protected === "true")

    const curLng = appCtx?.tenant?.currentLocale || '';

    const langCode = appCtx?.tenant?.currentLocale ?? appCtx?.tenant?.supportedLanguages[0] ?? "en"

    const navLink = useMemo(() => `/${langCode}/auth/forgot-password?email=${email}`, [email, langCode])

    const { commit, isError, errorMessage, isPending } = useApiFetch("POST", `/api/internal/auth/signin/`)

    const [isRedirecting, setRedirecting] = useState(false)

    const handleChange = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            const data = new FormData(e.currentTarget);

            var email = data.get("email")

            setEmail(email?.toString() ?? "")
        },
        [setEmail],
    )

    const handleSubmit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {

            const data = new FormData(e.currentTarget);

            var email = data.get("email")
            var password = data.get("password")

            e.preventDefault();

            commit(
                { projectcode: appCtx?.tenant?.projectCode, lang: curLng },
                { email: email?.toString()?.trim()?.replace(/ /g, "") ?? "", password: password },
                (e) => {
                    //@ts-ignore
                    if (e?.id) {
                        setRedirecting(true)

                        router.push(router?.query?.return as string ?? "/")
                    }
                })
        },
        [router, curLng],
    )

    const error = useMemo(() => errorMessage === "" && isError ? "Failed to login" : errorMessage, [isError, errorMessage])

    return <>
        {
            (fromProtectedResource) && <div className='d-flex text-center py-3'>
                <h3 className='font-semibold text-lg'>
                    {appCtx?.tenant?.texts.authThisContentIsProtected ?? "This content is protected"}
                </h3>
                <div className='d-flex pt-2'>
                    {appCtx?.tenant?.texts.authSignInToView ?? "Sign in to view"}
                </div>
            </div>
        }

        <FormError>{error}</FormError>

        <form
            onSubmit={handleSubmit}
            onChange={handleChange}
            id="promt-signin"
            className="flex flex-col space-y-2"
            action="#">

            <FormInput
                disabled={isPending || isRedirecting}
                id="form-email"
                label={appCtx?.tenant?.texts.authEmailLabelText ?? "Email"}
                type="email"
                autoComplete='username'
                name="email"
                required
                defaultValue={email}
                placeholder={appCtx?.tenant?.texts.authEmailLabelText ?? "Email"} />

            <div className="flex space-x-2 w-full">

                <FormInput
                    disabled={isPending || isRedirecting}
                    id="current-password"
                    label={appCtx?.tenant?.texts.authPasswordLabelText ?? "Password"}
                    required
                    type="password"
                    autoComplete="current-password"
                    name="password"
                    placeholder={appCtx?.tenant?.texts.authPasswordLabelText ?? "Password"} >
                    <FormButton
                        id="form-button"
                        className="w-14"
                        disabled={isPending || isRedirecting}>
                        <i className="fa-solid fa-arrow-right "></i>
                    </FormButton>
                </FormInput>

            </div>


            <div className="mb-2 flex items-center justify-end pb-2">
                <NavLink href={navLink}>
                    {appCtx?.tenant?.texts.authForgotPasswordText ?? "Forgot password?"}
                </NavLink>
            </div>
        </form >
    </>
}